<script>
  import classNames from 'classnames/bind'
  import {
    GridContainer,
    GridColumn,
    Text,
    Header,
    Separator,
    Picture,
    StoreButton,
    WidgetHeader,
    WidgetFooter,
  } from '@qlean/ui-kit-web-svelte'

  import css from './_styles.scss'

  const cN = classNames.bind(css)

  export let title
  export let subTitle
  export let titleTag
  export let withTopMargin
  export let withBottomMargin
  export let image
  export let stores
</script>

<WidgetHeader withMargin={withTopMargin} />

<GridContainer class={cN('mobile-app')}>
  <GridColumn width={0} widthMiddle={1} />
  <GridColumn widthMiddle={10} class={cN('mobile-app__container')}>
    <GridColumn width={0} widthMiddle={1} />
    <GridColumn widthMiddle={5} class={cN('mobile-app__text-container')}>
      <Header tag={titleTag}>{title}</Header>
      <Separator key={10} size={Separator.SIZES.MEDIUM} />
      <Text size={Text.SIZES.LARGE}>{subTitle}</Text>
      <Separator key={30} size={Separator.SIZES.SMALLEST} />
      <div class={cN('mobile-app__store-buttons')}>
        <StoreButton name={StoreButton.NAMES.APP_STORE} href={stores.appStoreLink} />
        <StoreButton name={StoreButton.NAMES.GOOGLE_PLAY} href={stores.playMarketLink} />
      </div>
    </GridColumn>
    <GridColumn widthMiddle={4}>
      <Picture type={Picture.PROPORTIONS.SQUARE} {...image} />
    </GridColumn>
  </GridColumn>
  <GridColumn width={0} widthMiddle={1} />
</GridContainer>

<WidgetFooter withMargin={withBottomMargin} />
